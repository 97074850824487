import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Typography,
  Stack,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  InputAdornment,
  Backdrop,
} from "@mui/material";
import { getIn, useFormik } from "formik";
import CustomTextfiled from "../../components/CustomTextField";
import { useResponsive } from "../../hooks/useRespnsiveHook";
import CustomCheckbox from "../../components/CustomCheckbox";
import {
  basicValidationSchema,
  companySchema,
} from "../../formik/contactUsSchema";
import axios from "axios";
import ThankYou from "../ThankYou";
import { baseUrl } from "../../config";
import ProprietorshipForm from "./ProprietorshipForm";
import PartnershipForm from "./PartnershipForm";
import PrivateLimiterForm from "./PrivateLimiterForm";
import CircularProgress from "@mui/material/CircularProgress";

const ContactUs = ({ positionHandler }) => {
  const mdDown = useResponsive("down", "md");

  const [selectedConstitution, setSelectedConstitution] =
    React.useState("proprietorship");

  const [isChecked, setIsChecked] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isProceed, setIsProceed] = useState(false);
  const [userId, setUserId] = useState(null);
  const [filesArray, setFilesArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleConstitutionChange = (event) => {
    const value = event.target.value;
    if (selectedConstitution != value) {
      setSelectedConstitution((prevSelected) =>
        prevSelected === value ? "" : value
      );
      setFilesArray([]);
    }
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    hotelName: "",
    companyName: "",
    companyPhone: "",
    pincode: "",
  };

  const handelProceed = async (value) => {
    // let value = formik.values;
    const {
      constitutionOfBusiness,
      companyAddress,
      businessAddress,
      ...part1
    } = value;
    setIsLoading(true);
    const response = await axios.post(
      `${baseUrl}/horeca1-discco/create-user`,
      part1
    );
    if (response.data) {
      setIsProceed(true);
      setIsLoading(false);
      setUserId(response.data.id);
    } else if (response.error) {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: basicValidationSchema,
    onSubmit: async (value) => {
      await handelProceed(value);
    },
  });

  const companyFormik = useFormik({
    initialValues: {
      companyAddress: {
        address: "",
        city: "",
        pincode: "",
        state: "",
      },
      businessAddress: {
        address: "",
        city: "",
        pincode: "",
        state: "",
      },
    },
    validationSchema: companySchema,
    validateOnMount: true,
    onSubmit: async (value) => {},
  });

  const handleSubmit1 = async (constitutionOfBusiness) => {
    await companyFormik.handleSubmit();
    if (companyFormik.isValid && constitutionOfBusiness) {
      const payload = {
        ...formik.values,
        ...companyFormik.values,
        constitutionOfBusiness: { ...constitutionOfBusiness },
        businessType: selectedConstitution,
      };
      setIsLoading(true);
      const response = await axios.patch(
        `${baseUrl}/horeca1-discco/update-user/${userId}`,
        payload
      );
      if (response.data) {
        let id = response.data.id;
        for (const filesObj of filesArray) {
          const fileFormData = new FormData();
          fileFormData.append("file", filesObj?.file);

          const { type, value } = filesObj;
          const jsonPayload = {
            type: type?.trim(),
            value: value ? value?.trim() : null,
            businessType: selectedConstitution,
          };

          fileFormData.append("json", JSON.stringify(jsonPayload));

          // Upload file with additional data
          await axios.post(
            `${baseUrl}/horeca1-discco/user/${userId}/file`,
            fileFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        }
        positionHandler();
        setIsLoading(false);
        setSubmitted(true);
      }
    }
  };

  const handleCopyAddress = async (checked) => {
    let fields = [
      {
        key: "businessAddress.address",
        val: "companyAddress.address",
      },
      {
        key: "businessAddress.city",
        val: "companyAddress.city",
      },
      {
        key: "businessAddress.pincode",
        val: "companyAddress.pincode",
      },
      {
        key: "businessAddress.state",
        val: "companyAddress.state",
      },
      
    ];
    if (checked) {
      
      let sourceFieldValue = getIn(companyFormik.values, "companyAddress.city");
      await companyFormik.setFieldValue("businessAddress.city", sourceFieldValue);
      sourceFieldValue = getIn(companyFormik.values, "companyAddress.pincode");
      await companyFormik.setFieldValue("businessAddress.pincode", sourceFieldValue);
      sourceFieldValue = getIn(companyFormik.values, "companyAddress.address");
      await companyFormik.setFieldValue("businessAddress.address", sourceFieldValue);
      sourceFieldValue = getIn(companyFormik.values, "companyAddress.state");
      await companyFormik.setFieldValue("businessAddress.state", sourceFieldValue);
    } else {
      await companyFormik.setFieldValue("businessAddress.city", '');
      await companyFormik.setFieldValue("businessAddress.pincode", '');
      await companyFormik.setFieldValue("businessAddress.state", '');
      companyFormik.setFieldValue("businessAddress.address", '');
    }
    
    fields.forEach((field) => {
      companyFormik.setFieldTouched(
        field.key,
        true, false
      );
    });
   //await companyFormik.validateForm()
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", mt: "2rem" }}
      id="contactUs"
      name="contactUs"
    >
      <Card
        sx={{
          maxWidth: "900px",
          width: "100%",
          padding: "1rem 2rem",
          borderRadius: "0.5rem",
        }}
      >
        {submitted ? (
          <Stack>
            <Typography
              variant="h4"
              color="initial"
              alignSelf={"center"}
              fontWeight={700}
            >
              Registered <span style={{ color: "red" }}> Successfully!</span>
            </Typography>
            <Typography
              variant="h6"
              color="initial"
              alignSelf={"center"}
              fontWeight={700}
            >
              To Get Financial Freedom
            </Typography>
          </Stack>
        ) : (
          <Stack>
            <Typography
              variant="h4"
              color="initial"
              alignSelf={"center"}
              fontWeight={700}
            >
              Register
            </Typography>
            <Typography
              variant="h6"
              color="initial"
              alignSelf={"center"}
              fontWeight={700}
            >
              To Get Financial Freedom
            </Typography>
          </Stack>
        )}
        {submitted ? (
          <ThankYou />
        ) : (
          <>
            <Box sx={{ marginTop: "1rem" }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                width="100%"
                // gap='1rem'
              >
                <Grid item xs={12} md={5.5} lg={5.5}>
                  <CustomTextfiled
                    name="firstName"
                    placeholder="First Name"
                    label="First Name"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} md={5.5} lg={5.5}>
                  <CustomTextfiled
                    name="lastName"
                    placeholder="Last Name"
                    label="Last Name"
                    formik={formik}
                  />
                </Grid>
                <Grid
                  container
                  xs={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <Grid item xs={12} sm={12} md={5.5}>
                    <TextField
                      size="small"
                      name="mobileNumber"
                      placeholder="Mobile Number"
                      value={getIn(formik.values, "mobileNumber")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        getIn(formik.touched, "mobileNumber") &&
                        Boolean(getIn(formik.errors, "mobileNumber"))
                      }
                      helperText={
                        getIn(formik.touched, "mobileNumber") &&
                        getIn(formik.errors, "mobileNumber")
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {"+91"}
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        mt: "1.5rem",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          background: "#e6f7ff",
                          borderRadius: "0.5rem",
                          "&:active": {
                            background: " white",
                          },
                          "&:hover": {
                            background: " white",
                          },
                          "& fieldset": {
                            // backgroundColor:"#F7F7FD",
                            borderColor: "#F7F7FD",
                          },
                          "& .MuiInputBase-input::placeholder": {
                            color: "#4d4d4d", // Change placeholder color
                          },
                          "&:hover .MuiInputBase-input::placeholder": {
                            color: "#212121", // Change placeholder color on hover
                          },
                          "&:hover fieldset": {
                            border: "1px solid  #e6f7ff",
                          },
                          "&.Mui-focused fieldset": {
                            border: "1px solid  rgb(0, 174, 255)",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5.5}>
                    <CustomTextfiled
                      name="email"
                      placeholder="Email"
                      label="Email"
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextfiled
                      name="hotelName"
                      placeholder="Hotel Name"
                      label="Hotel Name"
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextfiled
                      name="companyName"
                      placeholder="Company Name"
                      label="Company Name"
                      formik={formik}
                    />
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <Grid item xs={12} sm={12} md={5.5}>
                      <TextField
                        size="small"
                        name="companyPhone"
                        placeholder="Company Phone Number"
                        value={getIn(formik.values, "companyPhone")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          getIn(formik.touched, "companyPhone") &&
                          Boolean(getIn(formik.errors, "companyPhone"))
                        }
                        helperText={
                          getIn(formik.touched, "companyPhone") &&
                          getIn(formik.errors, "companyPhone")
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {"+91"}
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          mt: "1.5rem",
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            background: "#e6f7ff",
                            borderRadius: "0.5rem",
                            "&:active": {
                              background: " white",
                            },
                            "&:hover": {
                              background: " white",
                            },
                            "& fieldset": {
                              // backgroundColor:"#F7F7FD",
                              borderColor: "#F7F7FD",
                            },
                            "& .MuiInputBase-input::placeholder": {
                              color: "#4d4d4d", // Change placeholder color
                            },
                            "&:hover .MuiInputBase-input::placeholder": {
                              color: "#212121", // Change placeholder color on hover
                            },
                            "&:hover fieldset": {
                              border: "1px solid  #e6f7ff",
                            },
                            "&.Mui-focused fieldset": {
                              border: "1px solid  rgb(0, 174, 255)",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5.5} md={5.5}>
                      <CustomTextfiled
                        name="pincode"
                        placeholder="Area Pin Code"
                        label="Pincode"
                        formik={formik}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {isProceed && (
                  <Grid container mt={"1rem"} justifyContent={"space-between"}>
                    <Grid item xs={12} sm={12} md={5.5} lg={5.5}>
                      <Typography variant="h6" fontWeight={600}>
                        Company Address
                      </Typography>
                      <CustomTextfiled
                        name="companyAddress.address"
                        placeholder="Address"
                        label="Address"
                        formik={companyFormik}
                      />
                      <CustomTextfiled
                        name="companyAddress.city"
                        placeholder="City"
                        label="City"
                        formik={companyFormik}
                      />
                      <CustomTextfiled
                        name="companyAddress.pincode"
                        placeholder="Pin Code"
                        label="Pin Code"
                        formik={companyFormik}
                      />
                      <CustomTextfiled
                        name="companyAddress.state"
                        placeholder="State"
                        label="State"
                        formik={companyFormik}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5.5}
                      lg={5.5}
                      rowGap={"0.5rem"}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <Typography variant="h6" fontWeight={600}>
                          Business Address
                        </Typography>
                        <span style={{ fontSize: "12px" }}>
                          <CustomCheckbox
                            handleCopyAddress={handleCopyAddress}
                          />
                          Copy as same as company address
                        </span>
                      </Box>
                      <CustomTextfiled
                        name="businessAddress.address"
                        placeholder="Address"
                        label="Address"
                        formik={companyFormik}
                      />
                      <CustomTextfiled
                        name="businessAddress.city"
                        placeholder="City"
                        label="City"
                        formik={companyFormik}
                      />
                      <CustomTextfiled
                        name="businessAddress.pincode"
                        placeholder="Pin Code"
                        label="Pincode"
                        formik={companyFormik}
                      />
                      <CustomTextfiled
                        name="businessAddress.state"
                        placeholder="State"
                        label="State"
                        formik={companyFormik}
                      />
                    </Grid>
                  </Grid>
                )}
                {isProceed && (
                  <Grid mt={"1rem"}>
                    <Typography variant="h6" fontWeight={600}>
                      Constitution Of Business
                    </Typography>
                    <Stack direction={mdDown ? "column" : "row"}>
                      {[
                        { id: "proprietorship", name: "Proprietorship" },
                        { id: "partnershipLlp", name: "Partnership / LLP" },
                        {
                          id: "privateLimiter",
                          name: "Private Limited / Limited",
                        },
                      ].map((obj, index) => {
                        return (
                          <FormGroup key={index}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{
                                    "&.MuiCheckbox-root": {},
                                    "&.MuiCheckbox-root:hover": {
                                      backgroundColor: "#e6f7ff",
                                    },
                                    "&.MuiCheckbox-root.Mui-focusVisible": {
                                      backgroundColor: "#ecffb3", // Change background color on focus
                                      // Add other styles for focus state
                                    },
                                    "&.Mui-checked": {
                                      color: "#004666", // Change checkbox color when checked
                                      // Add other styles for checked state
                                    },
                                  }}
                                  checked={selectedConstitution.includes(
                                    obj.id
                                  )}
                                  onChange={handleConstitutionChange}
                                  value={obj.id}
                                />
                              }
                              label={obj.name}
                            />
                          </FormGroup>
                        );
                      })}
                    </Stack>
                    {selectedConstitution.includes("proprietorship") && (
                      <ProprietorshipForm
                        setFilesArray={setFilesArray}
                        handleSubmit={handleSubmit1}
                        uploadedFiles={filesArray}
                      />
                    )}
                    {selectedConstitution.includes("partnershipLlp") && (
                      <PartnershipForm
                        setFilesArray={setFilesArray}
                        handleSubmit={handleSubmit1}
                        uploadedFiles={filesArray}
                      />
                    )}
                    {selectedConstitution.includes("privateLimiter") && (
                      <PrivateLimiterForm
                        setFilesArray={setFilesArray}
                        handleSubmit={handleSubmit1}
                        uploadedFiles={filesArray}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </Box>
            {!isProceed && (
              <>
                <Box mt="3rem">
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "14px" }}>
                      <Checkbox
                        checked={isChecked}
                        onClick={() => setIsChecked(!isChecked)}
                        sx={{
                          "&.MuiCheckbox-root": {
                            width: "24px", // Set the desired width
                            height: "24px",
                            padding: "0px",
                            paddingRight: "0.5rem",
                          },
                          "&.MuiCheckbox-root:hover": {
                            backgroundColor: "#e6f7ff",
                          },
                          "&.MuiCheckbox-root.Mui-focusVisible": {
                            backgroundColor: "#ecffb3", // Change background color on focus
                            // Add other styles for focus state
                          },
                          "&.Mui-checked": {
                            color: "#004666", // Change checkbox color when checked
                            // Add other styles for checked state
                          },
                        }}
                      />
                      I agree to being contacted by representatives of Horeca1
                      or any of its Lending Partners to process the
                      Buy-Now-Pay-Later Credit Application process, Contact
                      Sharing & Bureau Pull consent
                    </Typography>
                  </span>
                </Box>
                <Box
                  mt={"2rem"}
                  display={"flex"}
                  justifyContent={"center"}
                  width={"100%"}
                >
                  <Button
                    variant="contained"
                    onClick={formik.handleSubmit}
                    disabled={!isChecked}
                    sx={{
                      "&.MuiButton-root": {
                        backgroundColor: "#e6f7ff",
                        borderRadius: "0.25rem",
                        borderColor: "#e6f7ff",
                        color: "#4d4d4d", // Text color
                        "&:hover": {
                          backgroundColor: "rgb(0, 174, 255)",
                          color: "black",
                          boxShadow: "0 0 5px rgba(0, 174, 255, 0.5)",
                        },
                        "&:focused": {
                          backgroundColor: "#004666",
                          color: "white",
                        },
                        "&:active": {
                          backgroundColor: "#004666",
                          color: "white",
                        },
                        "&:disabled": {
                          backgroundColor: "#e6faff",
                          color: "#4d4d4d",
                        },
                      },
                    }}
                  >
                    Proceed
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </Card>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default ContactUs;
