import React from 'react'
import {Typography, Box} from '@mui/material'
import ThumbsUpSvg from '../assets/thumbsUpPng.png'

const ThankYou = () => {
  return (
    <Box mt="2rem">
        <Box display={'flex'} justifyContent={'center'}><img src={ThumbsUpSvg} ></img></Box>
        <Typography variant='h5' sx={{fontWeight:600, color:'#004666', marginTop:'1rem'}}>Thanks for showing interest in registering for the most innovative B2B Buy-Now-Pay-Later solution for all your F&B Procurement. You’ll soon be contacted by Horeca1 / Lending Partner representatives to complete the process.</Typography>
    </Box>
  )
}

export default ThankYou