import React from 'react'
import horecaLogo from '../assets/logo.png'

const HorecaLogo = () => {
  return (
    <div style={{maxWidth: '100%', height: 'auto'}}>
      <img style={{width: '140px', height: "auto"}} src={horecaLogo} alt="Horeca Logo" />
    </div>
  )
}

export default HorecaLogo