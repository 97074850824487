import React, { useRef } from "react";
import { Box, Button, Divider } from "@mui/material";
import ContactUs from "./ContactUs/ContactUs";
import Slider from "../components/Slider";
import { useResponsive } from "../hooks/useRespnsiveHook";
import KnowMore from "./KnowMore";
import img1 from "../assets/images/slide-1.jpg";
import Carasousal from "../components/caraousal/Carasousal";
import CustomeInputButton from "../components/CustomeInputButton";

const Home = () => {
  const smDown = useResponsive("down", "sm");

  const contactUsRef = useRef(null);
  const knowMoreRef = useRef(null);

  const scrollToSection = (offset) => {
    window.scrollTo({
      top: offset,
      behavior: "smooth",
    });
  };

  const handleRegisterClick = () => {
    const contactUsOffset =
      document.getElementById("contactUs").offsetTop - 100;
    scrollToSection(contactUsOffset);
  };

  const handleKnowMoreClick = () => {
    const knowMoreOffset = document.getElementById("knowMore").offsetTop - 100;
    scrollToSection(knowMoreOffset);
  };

  return (
    <Box sx={{}}>
      {/* <div style={{ maxWidth: "100%", height: "auto" }}>
        <img
          alt="sample_file"
          src={img1}
          style={{ width: "100%", height: "500px" }}
        />
      </div> */}
      <Carasousal/>
      <Box
        sx={{
          marginTop: "2rem",
          display:  "flex",
          flexDirection: smDown ? "column" : "row",
          justifyContent: "center",
          gap: "1rem",
          padding: '0rem 1rem'
        }}
      >
        <CustomeInputButton
          name={"Please register me for early access"}
          handelClick={handleRegisterClick}
        />
        <CustomeInputButton
          name={"I Wish to know more about Horeca1 Discco"}
          handelClick={handleKnowMoreClick}
        />
      </Box>
      <Divider sx={{ mt: "2rem" }} />
      <ContactUs ref={contactUsRef} positionHandler={handleRegisterClick} />
      <Divider sx={{ mt: "2rem" }} />
      <KnowMore ref={knowMoreRef} />
    </Box>
  );
};

export default Home;
