import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import whatsAppChatLogo from '../assets/WhatsAppButtonGreenSmall.png'

const data = [
  {
    header: <Typography
    variant="h4"
    color="initial"
    fontWeight={600}
    marginTop={"2rem"}
    // display={Block}
    // flexWrap="wrap"  // Add this line to control wrapping
  >
    What is 
    <span style={{color:'red'}}> Horeca1 Discco?</span>
  </Typography>,
    subHeader: "",
    data: [
      "A Zero-hassle 15-60 days Credit facility for all your restaurant purchases!",
      "Discco allows you to streamline your Vendor Payments, get cash-discounts... all of it while enjoying credit upto 60 days.",
      "What’s more, we’ve made this Line-of-Credit closed-loop. Which means, it can be utilized only with us. Eliminates misutilization, completely.",
      "Consider it an overdraft facility for your restaurant purchases!",
      "As a Buy-Now-Pay-Later solution this allows you to breathe easy when business falls short of expectations, & helps you buy restaurant supplies.",
    ],
  },
  {
    header: 
    <Typography
    variant="h4"
    color="initial"
    fontWeight={600}
    marginTop={"2rem"}
    // display={'flex'}
  >Why do I <span style={{color:'red'}}> need Discco?</span></Typography>,
    subHeader: "",
    data: [
      "There are multiple benefits for every type of Hospitality businesses.",
      "If you have a great cash-flow, this will help:",
      <span style={{fontWeight:550}}># Simplify your  payments to your Vendors</span>,
      <span style={{fontWeight:550}}># Get Cash-Discounts, Special Offers on paying upfront</span>,
      "But if you are seeing an irregular cash-flow and need a little cushion to pay for your Daily Supplies & Consumables, this could be of great help.",
    ],
  },
  {
    header: 
    <Typography
    variant="h4"
    color="initial"
    fontWeight={600}
    marginTop={"2rem"}
  >What can I get on <span style={{color:'red'}}> Discco?</span></Typography>,
    subHeader: "All your restaurant supplies!",
    data: [
      "Groceries, Condiments, Sauces & Purees, Processed Foods, Dairy, Bakery & Desserts, Beverages, Frozen... you name it!",
      "Plus, we’re adding more categories & Vendors to make it easier for you.",
      "What’s more, shortly we’ll be adding your favorite suppliers as well!",
    ],
  },
  {
    header: 
    <Typography
    variant="h4"
    color="initial"
    fontWeight={600}
    marginTop={"2rem"}
    // display={'flex'}
  >What will it <span style={{color:"red"}}> Cost me?</span></Typography>,
    subHeader: "",
    data: [
      "ZERO!",
      "Zero joining Fee. Zero Annual Fee. And its Collateral Free.",
      "All you’ll have to pay is a minimal interest if your payment crosses your sanctioned Credit period.",
      "The interest depends on your Credit Score, your payment track record & the BNPL Credit provider.",
    ],
  },
  {
    header: 
    <Typography
    variant="h4"
    color="initial"
    fontWeight={600}
    marginTop={"2rem"}
  >I’m interested.<span style={{color:"red"}}> How do I join?</span></Typography>,
    subHeader: "",
    data: [
      "It's simple.",
      "Fill out the form above, if you already haven’t done so.",
      (<span>
        Or simply send us a message with your Outlet Name, Contact Person & Contact Details. Our representative will call and help you onboard.
        <a href={`https://wa.me/918369876700?text=${encodeURIComponent("I'm interested in Horeca1 DiSCCO. Buy-Now-Pay-Later. Please help me Register.")}`} target='_blank' style={{ verticalAlign: 'middle' }}>
        <img src={whatsAppChatLogo} width="100" alt='whatsapp' style={{marginLeft:3, marginTop:1}}/>
        </a>
      </span>),
      "Different Credit providers may have different criteria and processes for KYC evaluation & credit sanction. Hence we urge you to get registered with all the BNPL Credit partners we have on board.",
    ],
  },
  {
    header:
    <Typography
    variant="h4"
    color="initial"
    fontWeight={600}
    marginTop={"2rem"}
  >Why am I asked to upload my <span style={{color:"red"}}> KYC details?</span></Typography>,
    subHeader: "",
    data: [
      "As a Zero-collateral Line-of-Credit, there are certain norms to sanction the credit limit. In fact even collateral-based mortgage loans require KYC documents. So don’t worry. It's just like getting a credit card for your restaurant supplies procurement. ",
    ],
  },
  {
    header: 
    <Typography
    variant="h4"
    color="initial"
    fontWeight={600}
    marginTop={"2rem"}
  >Are there any hidden <span style={{color:"red"}}>  terms & Conditions?</span></Typography>,
    subHeader: "",
    data: [
      <span style={{fontWeight:550}}>No None.</span>,
      "But do note, that terms & conditions may differ from one BNPL Credit provider to another. Sanction of credit and the related Terms & Conditions is totally dependent on our Lending Partners",
    ],
  },
];

//together alone
const KnowMore = () => {
  return (
    <Box sx={{width: "100%",display:'flex', justifyContent:'center'}}>
      <Box sx={{marginTop: "1rem", maxWidth: "900px", padding: '0 1rem'}}>
      <Typography variant="h3" fontWeight={600} id="knowMore" name="knowMore">Know More</Typography>
      {data.map((horecaData, index) => {
        return (
          <Box key={index}>
            {horecaData.header}
            <Stack gap="1rem" mt={"0.5rem"}>
                {
                  (horecaData.data).map((d, index)=>{
                    return(
                      <Typography variant="body1" color="initial" fontWeight={400} key={index}>
                      {d}
                    </Typography>
                    )
                  })
                }
            </Stack>
          </Box>
        );
      })}
    </Box>
    </Box>
  );
};

export default KnowMore;