import { TextField, Typography, useTheme, Box } from "@mui/material";
import { getIn } from "formik";

const CustomTextfiled = (props) => {
  const theme = useTheme();
  const { formik, name, type, label, placeholder, inputProps } = props;
  return (
    <Box mt='1.5rem'>
      {/* <Typography
        color={theme.palette.text.secondary}
        sx={{ pb: 0.5 }}
      >
        {label}
      </Typography> */}
      <TextField
        variant="outlined"
        size="small"
        type={type ? type : "text"}
        name={name}
        value={getIn(formik.values, name)}
        onChange={formik.handleChange}
        placeholder={placeholder}
        fullWidth
        onBlur={formik.handleBlur}
        inputProps={inputProps}
        error={
          getIn(formik.touched, name) && Boolean(getIn(formik.errors, name))
        }
        helperText={getIn(formik.touched, name) && getIn(formik.errors, name)}
        sx={{
          '& .MuiOutlinedInput-root': {
            background: "#e6f7ff",
            borderRadius: '0.5rem',
            '&:active': {
              background:' white'
            },
            '&:hover': {
              background:' white'
            },
            '& fieldset': {
              // backgroundColor:"#F7F7FD",
              borderColor:"#F7F7FD"
            },
            '& .MuiInputBase-input::placeholder': {
              color: '#4d4d4d', // Change placeholder color
            },
            '&:hover .MuiInputBase-input::placeholder': {
              color: '#212121', // Change placeholder color on hover
            },
            '&:hover fieldset': {
              border: '1px solid  #e6f7ff',
            },
            '&.Mui-focused fieldset': {
              border: '1px solid  rgb(0, 174, 255)',
            },
          },
        }}
      />
    </Box>
  );
};

export default CustomTextfiled;
