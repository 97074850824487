import * as Yup from "yup";

export const proprietorshipSchema1=Yup.object().shape({
    proofOfBusiness: Yup.object().shape({
      otherDoc: Yup.object().shape({
        name: Yup.string().required(
          "Please provide the name for the other document"
        ),
        buffer: Yup.string().required("Please upload the other document"),
      }),
    }),
    individualPromoterDetails: Yup.object().shape({
      panCard: Yup.object().shape({
        name: Yup.string().trim().required(
          "Please provide the name for the PAN card"
        ),
        number: Yup.string().trim()
          .required("Please provide the PAN card number")
          .matches(
            /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
            "PAN Card Number must be valid"
          ),
        buffer: Yup.string().required("Please upload the PAN card"),
      }),
      otherDoc: Yup.object().shape({
        name: Yup.string().required(
          "Please provide the name of the document"
        ),
        buffer: Yup.string().required("Please upload the document"),
      }),
      recentPassportSizePhoto: Yup.object().shape({
        name: Yup.string().required("Please provide the name for the photo"),
        buffer: Yup.string().required("Please upload the photo"),
      }),
    }),
    banking: Yup.object().shape({
      name: Yup.string().required(
        "Please provide the name for the banking document"
      ),
      buffer: Yup.string().required("Please upload the banking document"),
    }),
})

export const privateLimiterSchema1 = Yup.object().shape({
    proofOfBusiness: Yup.object().shape({
      gstCertificate: Yup.object().shape({
        name: Yup.string().trim().required("GST Certificate Name is required"),
        number: Yup.string().trim()
          .required("GST Certificate Number is required")
          .matches(
            /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$/,
            "GST Certificate Number must be valid"
          ),
        buffer: Yup.string().required("GST Certificate File is required"),
      }),
      companyPanCard: Yup.object().shape({
        name: Yup.string().trim().required("Company PAN Card Name is required"),
        number: Yup.string().trim()
          .required("Company PAN Card Number is required")
          .matches(
            /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
            "Company Pan Number must be valid"
          ),
        buffer: Yup.string().required("Company PAN Card File is required"),
      }),
      otherDoc: Yup.object().shape({
        name: Yup.string().required("Other Document Name is required"),
        buffer: Yup.string().required("Other Document File is required"),
      }),
    }),
    individualPromoterDetails: Yup.object().shape({
      panCard: Yup.object().shape({
        name: Yup.string().trim().required("PAN Card Name is required"),
        number: Yup.string().trim()
          .required("PAN Card Number is required")
          .matches(/^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/, "PAN Number must be valid"),
        buffer: Yup.string().required("PAN Card File is required"),
      }),
      otherDoc: Yup.object().shape({
        name: Yup.string().required("Other Document Name is required"),
        buffer: Yup.string().required("Other Document File is required"),
      }),
      recentPassportSizePhoto: Yup.object().shape({
        name: Yup.string().required(
          "Recent Passport Size Photo Name is required"
        ),
        buffer: Yup.string().required(
          "Recent Passport Size Photo File is required"
        ),
      }),
    }),
    banking: Yup.object().shape({
      name: Yup.string().required("Banking Name is required"),
      buffer: Yup.string().required("Banking File is required"),
    }),
})

export const partnershipLlpSchema1 = Yup.object().shape({
    proofOfBusiness: Yup.object().shape({
      gstCertificate: Yup.object().shape({
        name: Yup.string().trim().required("GST Certificate Name is required"),
        number: Yup.string().trim()
          .required("GST Certificate Number is required")
          .matches(
            /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$/,
            "GST Certificate Number must be valid"
          ),
        buffer: Yup.string().required("GST Certificate File is required"),
      }),
      companyPanCard: Yup.object().shape({
        name: Yup.string().trim().required("Company PAN Card Name is required"),
        number: Yup.string().trim()
          .required("Company PAN Card Number is required")
          .matches(
            /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
            "Company Pan Number must be valid"
          ),
        buffer: Yup.string().required("Company PAN Card File is required"),
      }),
      patnershipDidCopy: Yup.object().shape({
        name: Yup.string().required("Partnership Deed Copy Name is required"),
        buffer: Yup.string().required(
          "Partnership Deed Copy File is required"
        ),
      }),
    }),
    individualPromoterDetails: Yup.object().shape({
      panCard: Yup.object().shape({
        name: Yup.string().trim().required("PAN Card Name is required"),
        number: Yup.string().trim()
          .required("PAN Card Number is required")
          .matches(/^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/, "Pan  Number must be valid"),
        buffer: Yup.string().required("PAN Card File is required"),
      }),
      otherDoc: Yup.object().shape({
        name: Yup.string().required("Other Document Name is required"),
        buffer: Yup.string().required("Other Document File is required"),
      }),
      recentPassportSizePhoto: Yup.object().shape({
        name: Yup.string().required(
          "Recent Passport Size Photo Name is required"
        ),
        buffer: Yup.string().required(
          "Recent Passport Size Photo File is required"
        ),
      }),
    }),
    banking: Yup.object().shape({
      name: Yup.string().required("Banking Name is required"),
      buffer: Yup.string().required("Banking File is required"),
    }),
  })

export const basicValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  mobileNumber: Yup.string().trim()
    .required("Mobile Number is required")
    .matches(/^[0-9\s]{10}$/, "Mobile Number must be a 10-digit number"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  hotelName: Yup.string().required("Hotel Name is required"),
  companyName: Yup.string()
    .required("Company Name is required"),
  companyPhone: Yup.string().trim().required("Company Phone is required").matches(/^[0-9]{10}$/, "Company Phone Number must be a 10-digit number"),
  pincode: Yup.string().trim()
    .required("Pincode is required")
    .matches(/^[0-9\s]{6}$/, "Pin Code Must be a number only"),
});

export const companySchema = Yup.object().shape({
  companyAddress: Yup.object().shape({
    address: Yup.string().required("Company Address is required"),
    city: Yup.string().required("City is required"),
    pincode: Yup.string().trim().required("Company Pincode is required").matches(/^[0-9\s]{6}$/,"Pincode must be 6 digits."),
    state: Yup.string().required("State is required"),
  }),
  businessAddress: Yup.object().shape({
    address: Yup.string().required("Business Address is required"),
    city: Yup.string().required("Business City is required"),
    pincode: Yup.string().trim().required("Business Pincode is required").matches(/^[0-9\s]{6}$/,"Pincode must be 6 digits."),
    state: Yup.string().required("Business State is required"),
  })
})
