import React from 'react';
import Button from '@mui/material/Button';

const CustomInputButton = ({variant, name, handelClick, disable}) => {
  return (
    <Button
      variant={variant} // You can change the variant as needed
      onClick={()=>handelClick()}
      sx={{
        '&.MuiButton-root': {
          backgroundColor: '#e6f7ff',
          borderRadius: '0.25rem',
          borderColor:"#e6f7ff",
          color: '#4d4d4d', // Text color
          '&:hover': { 
            backgroundColor:"rgb(0, 174, 255)",
            color: 'black',
            boxShadow: "0 0 5px rgba(0, 174, 255, 0.5)"
          },
          '&:focused': {
            backgroundColor: '#004666',
            color:'white'
          },
          '&:active': {
            backgroundColor: '#004666',
            color: 'white'
          },
        },
      }}
    >
      {name}
    </Button>
  );
};

export default CustomInputButton;
