import React from 'react'
import {Checkbox} from '@mui/material'

const CustomCheckbox = ({handleCopyAddress}) => {

    const handelCopyAdd = (e) => {
        handleCopyAddress(e.target.checked);
    }
  return (
    <Checkbox
    onClick={handelCopyAdd}
    sx={{
      "&.MuiCheckbox-root": {    
        width: '24px', // Set the desired width
        height: '24px',
        padding: "0px"
      },
      "&.MuiCheckbox-root:hover": {
        backgroundColor: "#e6f7ff", // Change background color on hover
        // Add other styles for hover state
      },
      "&.MuiCheckbox-root.Mui-focusVisible": {
        backgroundColor: "#e6f7ff", // Change background color on focus
        // Add other styles for focus state
      },
      '&.Mui-checked': {
        color: '#004666',
      },
    }}
  />
  )
}

export default CustomCheckbox