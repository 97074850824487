import React from 'react'
import EmblaCarousel from './EmblaCaraousal'
import '../css/base.css'
import '../css/sandbox.css'
import '../css/embla.css'

const Carasousal = () => {

  const OPTIONS = {}
const SLIDE_COUNT = 3
const SLIDES = Array.from(Array(SLIDE_COUNT).keys())

  return (
    <>
      <main className="sandbox">
    <section className="sandbox__carousel">
      <EmblaCarousel slides={SLIDES} options={OPTIONS} />
    </section>
    </main>
    </>
  )
}

export default Carasousal