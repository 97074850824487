import React from 'react';
import {Routes, Route} from 'react-router-dom';
import CusomtContainer from './components/CustomContainer';
import KnowMore from './pages/KnowMore';
import Home from './pages/Home';

const App = () => {
  return (
    <Routes>
          <Route element={<CusomtContainer />}>
            <Route path='/' element={<Home/>}/>
            <Route path='/know-more' element={<KnowMore/>} />
          </Route>
    </Routes>
    // <Header/>
  )
}

export default App