import React from 'react'
import {Container, Button, TextField, Box} from '@mui/material'
import {Outlet} from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
// import ShowMessage from '../showMessage/ShowMessage'
import whatsAppLogo from '../assets/whatsapp.png'



const CusomtContainer = () => {
  return (
      <Container maxWidth="xl" sx={{
        '&.MuiContainer-root': {
        //   maxWidth: 'xl',
          paddingLeft: '0px',
          paddingRight: '0px',
        //   backgroundColor:"#F2F0DF"
          // Add any additional styles here
        },
      }}>
        <Header/>
        <Outlet/>
        <Box
        sx={{
          zIndex: 1000,
          position: 'fixed',
          bottom: 0,
          right: 0,  // Set right to 0 for the bottom right corner
          padding: '1rem',
        }}
      >
        <a href="https://wa.me/918369876700?text=I'm interested in Horeca1 DiSCCO - Buy-Now-Pay-Later. 
Please help me Register." target='_blank'>
          <img src={whatsAppLogo} width="60" alt='whatsapp' />
        </a>
      </Box>
        <Footer/>
      </Container>
  )
}

export default CusomtContainer