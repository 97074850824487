import banner1 from '../../assets/images/banner1.png'
import banner2 from '../../assets/images/banner2.png'
import banner3 from '../../assets/images/banner3.png'

import mBanner1 from '../../assets/images/mbanner1.png'
import mBanner2 from '../../assets/images/mbanner2.png'
import mBanner3 from '../../assets/images/mbanner3.png'




export const images = [banner1, banner2, banner3]

export const mImages = [mBanner1, mBanner2, mBanner3]

export const imageByIndex = (index) => images[index % images.length]

export const mImageByIndex = (index) => mImages[index % images.length]

// export default imageByIndex
