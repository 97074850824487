import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import FormHelperText from '@mui/material/FormHelperText';
import { useMediaQuery } from "@mui/material";

const FileUpload = ({
  acceptedFileTypes,
  maxFileSize,
  formik,
  name,
  setFilesArray,
  type,
  value,
  uploadedFiles,
  key
}) => {


  const setFileUpload = (file) => {
    const obj= {
      type: type,
      value: value ? value : null,
      file: file,
      name:name
    }
    formik.setFieldValue(name, "FILE_TEXT");
    setFilesArray((prevFiles) => [...prevFiles, obj]);
  };



  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file && isFileValid(file)) {
        setFileUpload(file);
      }
    },
    accept: acceptedFileTypes,
    maxSize: maxFileSize,
  });


  const uploadedFile = useMemo(() => {
    let fileData =  uploadedFiles.filter((file)=>file.name===name)
   return fileData[0]?.file
  }, [uploadedFiles])
  
  useEffect(() => {
    if (type) {
      let fileData = uploadedFiles.find((file) => file.name === name)
      if (fileData) {
        fileData["type"] = type;
        let allFiles =  uploadedFiles.filter((file)=>file.name!==name)
        setFilesArray((prevFiles) => [...allFiles, fileData]);
      }
    }
  }, [type])
  
  useEffect(() => {
    if (value) {
      let fileData = uploadedFiles.find((file) => file.name === name)
      if (fileData) {
        fileData["value"] = value;
        let allFiles =  uploadedFiles.filter((file)=>file.name!==name)
        setFilesArray((prevFiles) => [...allFiles, fileData]);
      }
    }
  },[value])

  // const handleDeleteFile = (index) => {
  //   // Create a new array without the file at the specified index
  //   setFilesArray((prevFiles) => prevFiles.filter((_, i) => i !== index));
  // };

  const isFileValid = (file) => {
    if (acceptedFileTypes && acceptedFileTypes.length > 0) {
      const fileType = file.type.split("/")[1];
      if (!acceptedFileTypes.includes(fileType)) {
        alert(
          `Invalid file type. Accepted types: ${acceptedFileTypes.join(", ")}`
        );
        return false;
      }
    }

    if (maxFileSize && file.size > maxFileSize) {
      alert(`File size exceeds the limit of ${maxFileSize / (1024 * 1024)} MB`);
      return false;
    }

    return true;
  };

  const handleDelete = () => {
    // setUploadedFile(null);
    let fileData =  uploadedFiles.filter((file)=>file.name!==name)
    formik.setFieldValue(name, null);
    setFilesArray(fileData);
  };

  return (
    <div style={containerStyle}>
      <input {...getInputProps()} />
      {uploadedFile ? (
        <div style={previewContainerStyle}>
          <img
            src={URL.createObjectURL(uploadedFile)}
            alt={uploadedFile.name}
            style={previewImageStyle}
          />
          <button onClick={handleDelete} style={deleteButtonStyle}>
            <DeleteIcon style={{ fontSize: 16, color: "white" }} />
          </button>
        </div>
      ) : (
        <div {...getRootProps()} style={uploadContainerStyle(isDragActive)}>
          <CloudUploadIcon style={{ fontSize: 16, color: "#004666" }} />
          <span style={uploadText}>Upload File</span>
        </div>
      )}
      {formik.getFieldMeta(name).error && formik.getFieldMeta(name).touched && (
        <FormHelperText id="component-error-text" sx={{color:'#D32F2F'}}>{formik.getFieldMeta(name).error}</FormHelperText>
      )}
    </div>
  );
};

const containerStyle = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
};

const uploadContainerStyle = (isDragActive) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "8px",
  border: `1px dashed ${isDragActive ? "#4CAF50" : "#ccc"}`,
  borderRadius: "4px",
  cursor: "pointer",
});

const previewContainerStyle = {
  position: "relative",
  width: "100px",
  height: "100px",
};

const previewImageStyle = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "4px",
};

const uploadText = {
  marginLeft: "8px",
};

const deleteButtonStyle = {
  position: "absolute",
  top: "4px",
  right: "4px",
  background: "red",
  color: "white",
  border: "none",
  padding: "4px",
  borderRadius: "50%",
  cursor: "pointer",
};

export default FileUpload;
