import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Button,
  FormHelperText,
} from "@mui/material";
import React from "react";
import FileUpload from "../../components/fileupload/FileUpload";
import { useFormik, getIn } from "formik";
import CustomTextfiled from "../../components/CustomTextField";
import { partnershipLlpSchema1 } from "../../formik/contactUsSchema";

const PartnershipForm = ({ setFilesArray, handleSubmit,uploadedFiles }) => {
  const formik = useFormik({
    initialValues: {
      proofOfBusiness: {
        gstCertificate: {
          name: "gstCertificate",
          number: "",
          buffer: null,
        },
        companyPanCard: {
          name: "companyPanCard",
          number: "",
          buffer: null,
        },
        patnershipDidCopy: {
          name: "patnershipDidCopy",
          buffer: null,
        },
      },
      individualPromoterDetails: {
        panCard: {
          name: "panCard",
          number: "",
          buffer: null,
        },
        otherDoc: {
          name: "",
          buffer: null,
        },
        recentPassportSizePhoto: {
          name: "recentPassportSizePhoto",
          buffer: null,
        },
      },
      banking: {
        name: "banking",
        buffer: null,
      },
    },
    validateOnMount: true,
    validationSchema: partnershipLlpSchema1,
    onSubmit: async (value) => {
      const { constitutionOfBusiness } = value;
      //   await handelProceed(value);
    },
  });

  const handleFormikSubmit = () => {
    formik.handleSubmit();
    if (formik.isValid) {
      handleSubmit(formik.values);
    } else {
      handleSubmit(undefined);
    }
  };
  return (
    <>
      <Box width={"100%"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box>
              <Typography variant="h6" fontWeight={600}>
                Proof of Business
              </Typography>
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                gap={"1rem"}
                mt={"1rem"}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography fontWeight={550}>GST Certificate</Typography>
                    <Box maxWidth={320}>
                      <CustomTextfiled
                        name={`proofOfBusiness.gstCertificate.number`}
                        placeholder="Gst Number"
                        formik={formik}
                        inputProps={{style: {textTransform: 'uppercase'}}}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <FileUpload
                      name={`proofOfBusiness.gstCertificate.buffer`}
                      uploadedFiles={uploadedFiles}
                      setUploadedFile={""}
                      formik={formik}
                      setFilesArray={setFilesArray}
                      type={formik.values.proofOfBusiness?.gstCertificate.name}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography fontWeight={550}>Company PAN Card</Typography>
                    <Box maxWidth={320}>
                      <CustomTextfiled
                        name={`proofOfBusiness.companyPanCard.number`}
                        placeholder="Company Pan Card Number"
                        formik={formik}
                        inputProps={{style: {textTransform: 'uppercase'}}}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <FileUpload
                      name={`proofOfBusiness.companyPanCard.buffer`}
                      uploadedFiles={uploadedFiles}
                      setUploadedFile={""}
                      formik={formik}
                      setFilesArray={setFilesArray}
                      type={formik.values.proofOfBusiness?.companyPanCard.name}
                      value={
                        formik.values.proofOfBusiness?.companyPanCard.number
                      }
                      key="otherDoc"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography fontWeight={550}>
                      Partnership Deed true copy
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FileUpload
                      name={`proofOfBusiness.patnershipDidCopy.buffer`}
                      uploadedFiles={uploadedFiles}
                      setUploadedFile={""}
                      formik={formik}
                      setFilesArray={setFilesArray}
                      type={
                        formik.values.proofOfBusiness?.patnershipDidCopy.name
                      }
                      value={null}
                      key="otherDoc"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Typography variant="h6" fontWeight={600}>
                Individual / Promoter Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={550}>Pan Card</Typography>
                  <Box maxWidth={320}>
                    <CustomTextfiled
                      name={`individualPromoterDetails.panCard.number`}
                      placeholder="Pan Card Number"
                      formik={formik}
                      inputProps={{style: {textTransform: 'uppercase'}}}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FileUpload
                    name={`individualPromoterDetails.panCard.buffer`}
                    uploadedFiles={uploadedFiles}
                    setUploadedFile={""}
                    formik={formik}
                    setFilesArray={setFilesArray}
                    type={formik.values.individualPromoterDetails.panCard.name}
                    value={
                      formik.values.individualPromoterDetails.panCard.number
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl style={{ minWidth: 320 }}>
                    <Select
                      name={`individualPromoterDetails.otherDoc.name`}
                      value={
                        formik.values.individualPromoterDetails?.otherDoc.name
                      }
                      onChange={formik.handleChange}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      error={
                        getIn(
                          formik.touched,
                          `individualPromoterDetails.otherDoc.name`
                        ) &&
                        Boolean(
                          getIn(
                            formik.errors,
                            `individualPromoterDetails.otherDoc.name`
                          )
                        )
                      }
                      helperText={
                        getIn(
                          formik.touched,
                          `individualPromoterDetails.otherDoc.name`
                        ) &&
                        getIn(
                          formik.errors,
                          `individualPromoterDetails.otherDoc.name`
                        )
                      }
                      input={<OutlinedInput size="small" />}
                      sx={{
                        "&.MuiSelect-root": {
                          borderColor: "#F7F7FD",
                          background: "#F7F7FD",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#F7F7FD",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid  #e6f7ff",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid  rgb(0, 174, 255)",
                        },
                      }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <em style={{ color: "grey" }}>Select Any One</em>
                          );
                        }
                        return selected;
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>Select Any One</em>
                      </MenuItem>
                      {[
                        "Aadhar Card",
                        "Passport",
                        "Rent Agreement (Notarized)",
                        "Voter's ID",
                        "Driving License",
                      ].map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    {getIn(formik.touched, `individualPromoterDetails.otherDoc.name`) &&
                      getIn(formik.errors, `individualPromoterDetails.otherDoc.name`) && (
                        <FormHelperText
                          sx={{
                            color: (theme) => theme.palette.error.main,
                          }}
                        >
                          {getIn(
                            formik.errors,
                            `individualPromoterDetails.otherDoc.name`
                          )}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FileUpload
                    name={`individualPromoterDetails.otherDoc.buffer`}
                    uploadedFiles={uploadedFiles}
                    setUploadedFile={""}
                    formik={formik}
                    setFilesArray={setFilesArray}
                    type={formik.values.individualPromoterDetails.otherDoc.name}
                    value={null}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={550}>
                    Recent passport size coloured photograph
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FileUpload
                    name={`individualPromoterDetails.recentPassportSizePhoto.buffer`}
                    uploadedFiles={uploadedFiles}
                    setUploadedFile={""}
                    formik={formik}
                    setFilesArray={setFilesArray}
                    type={
                      formik.values.individualPromoterDetails
                        .recentPassportSizePhoto.name
                    }
                    value={null}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" fontWeight={600}>
                Banking
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  Latest 6-month bank statement duly downloaded from net banking
                  in PDF format including Current Account and WC
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FileUpload
                  name={`banking.buffer`}
                  uploadedFiles={uploadedFiles}
                  setUploadedFile={""}
                  formik={formik}
                  setFilesArray={setFilesArray}
                  type={formik.values.banking.name}
                  value={null}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        mt={"2rem"}
        display={"flex"}
        justifyContent={"center"}
        width={"100%"}
      >
        <Button
          variant="contained"
          onClick={handleFormikSubmit}
          sx={{
            "&.MuiButton-root": {
              backgroundColor: "#e6f7ff",
              borderRadius: "0.25rem",
              borderColor: "#e6f7ff",
              color: "#4d4d4d", // Text color
              "&:hover": {
                backgroundColor: "rgb(0, 174, 255)",
                color: "black",
                boxShadow: "0 0 5px rgba(0, 174, 255, 0.5)",
              },
              "&:focused": {
                backgroundColor: "#004666",
                color: "white",
              },
              "&:active": {
                backgroundColor: "#004666",
                color: "white",
              },
              "&:disabled": {
                backgroundColor: "#e6faff",
                color: "#4d4d4d",
              },
            },
          }}
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

export default PartnershipForm;
