import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import HorecaLogo from './HorecaLogo'; // Import a CSS file for styling (if needed)
import { useResponsive } from '../hooks/useRespnsiveHook';

const Footer = () => {
  const smDown = useResponsive('down', 'sm')
  return (
    <Box mt="2rem">
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ minHeight: '100px', backgroundColor: '#e6f7ff',padding: '1rem', flexDirection:smDown ? 'column' : '', alignItems:smDown ? 'center' : ''}}
        // padding={"0 2rem"}
      >
        <Box display={'flex'} flexDirection={'column'} gap={'0.5rem'}>
          <Typography variant="body2" color="textSecondary">
          C-003, Railway Station Complex, Sanpada Navi Mumbai Maharashtra - 400705
          </Typography>
          <Typography variant="body2" color="textSecondary">
          +91-7710920002 
          </Typography>
          <Typography variant="body2" color="textSecondary">
          sales@horeca1.com
          </Typography>
        </Box>
        <HorecaLogo />
        <Typography variant="body2" color="textSecondary">
          &copy; 2024 HCX Global Pvt. Ltd.. All rights reserved.
        </Typography>
      </Grid>
    </Box>
  );
};

export default Footer;
